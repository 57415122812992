
import { NEWS_PAGE_PATH_MAP } from '@/constants/index.js';

export default {
  props: {
    obj: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      NEWS_PAGE_PATH_MAP
    };
  }
};
