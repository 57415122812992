
import {
  NEWS_TYPE,
  NEWS_TYPE_LABEL,
  NEWS_PAGE_PATH_MAP
} from '@/constants/index.js';
import MxLink from '@/components/MxLink.vue';

export default {
  components: { MxLink },

  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      NEWS_TYPE,
      NEWS_TYPE_LABEL,
      NEWS_PAGE_PATH_MAP
    };
  },

  computed: {
    bigArray() {
      return this.list.slice(0, 2);
    },
    smallArray() {
      return this.list.length > 5 ? this.list.slice(2, 4) : this.list.slice(2);
    },
    bannerArray() {
      const list = this.list.length > 5 ? this.list.slice(4) : [];
      return list.length === 2 ? [...list, ...list] : list;
    }
  }
};
