import { render, staticRenderFns } from "./TopicAndNews.vue?vue&type=template&id=3b7476ff&scoped=true&"
import script from "./TopicAndNews.vue?vue&type=script&lang=js&"
export * from "./TopicAndNews.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7476ff",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTopic: require('D:/ruanjian/MX/tnaot_www_pc/components/HotTopic.vue').default,LatestNews: require('D:/ruanjian/MX/tnaot_www_pc/components/LatestNews.vue').default,ScrollToFixed: require('D:/ruanjian/MX/tnaot_www_pc/components/ScrollToFixed.vue').default})
