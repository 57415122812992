
import { Swiper, Navigation, Pagination, Autoplay, Keyboard } from 'swiper';
import { getRandomId } from '../utils/helper';
import Icon from './Icon.vue';

Swiper.use([Navigation, Pagination, Autoplay, Keyboard]);
const paginationId = getRandomId(16);

export default {
  name: 'SwiperContainer',
  components: { Icon },
  props: {
    swiperContainerClass: {
      type: [Object, Array, String],
      default: ''
    },
    initialSlide: {
      type: [Number],
      default: 0
    },
    direction: {
      type: [String],
      default: 'horizontal'
    },
    speed: {
      type: [Number],
      default: 300
    },
    autoHeight: {
      type: [Boolean],
      default: true
    },
    autoplay: {
      type: [Boolean, Object],
      default: () => ({
        delay: 3000,
        disableOnInteraction: false
      })
    },
    nested: {
      type: [Boolean],
      default: false
    },
    effect: {
      type: [String],
      default: 'slide'
    },
    watchOverflow: {
      type: [Boolean],
      default: false
    },
    spaceBetween: {
      type: [Number, String],
      default: 0
    },
    slidesPerView: {
      type: [Number, String],
      default: 'auto'
    },
    freeMode: {
      type: Boolean,
      default: false
    },
    slidesPerColumn: {
      type: [Number],
      default: 1
    },
    slidesPerColumnFill: {
      type: [String],
      default: 'column'
    },
    slidesPerGroup: {
      type: [Number],
      default: 1
    },
    centeredSlides: {
      type: [Boolean],
      default: false
    },
    centeredSlidesBounds: {
      type: [Boolean],
      default: false
    },
    slidesOffsetBefore: {
      type: [Number],
      default: 0
    },
    slidesOffsetAfter: {
      type: [Number],
      default: 0
    },
    centerInsufficientSlides: {
      type: [Boolean],
      default: false
    },
    allowTouchMove: {
      type: [Boolean],
      default: true
    },
    threshold: {
      type: [Number],
      default: 0
    },
    allowSlidePrev: {
      type: [Boolean],
      default: true
    },
    allowSlideNext: {
      type: [Boolean],
      default: true
    },
    preventClicks: {
      type: [Boolean],
      default: false
    },
    preventClicksPropagation: {
      type: [Boolean],
      default: false
    },
    slideToClickedSlide: {
      type: [Boolean],
      default: true
    },
    watchSlidesProgress: {
      type: [Boolean],
      default: false
    },
    watchSlidesVisibility: {
      type: [Boolean],
      default: true
    },
    preloadImages: {
      type: [Boolean],
      default: true
    },
    updateOnImagesReady: {
      type: [Boolean],
      default: true
    },
    loop: {
      type: [Boolean],
      default: true
    },
    pagination: {
      type: [Boolean, Object],
      default: () => ({
        el: `#${paginationId}`,
        type: 'bullets',
        clickable: true
      })
    },
    navigation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      paginationId,
      swiperOptions: {},
      activeIndex: 0
    };
  },

  beforeDestroy() {
    if (this.$swiper) {
      this.$swiper.destroy();
    }
  },
  mounted() {
    this.init();
  },

  methods: {
    init() {
      const swiperOptions = {
        ...this.$props,
        observer: {
          type: [Boolean],
          default: true
        },
        observeParents: {
          type: [Boolean],
          default: true
        },
        observeSlideChildren: {
          type: [Boolean],
          default: true
        },
        on: {
          init: swiper => {
            swiper.update();
            swiper.updateAutoHeight();
          },
          imagesReady: swiper => {
            swiper.update();
            swiper.updateAutoHeight();
          },
          observerUpdate: swiper => {
            swiper.update();
            // eslint-disable-next-line no-unused-vars
            const currentSlide = swiper.slides;
            swiper.updateAutoHeight();
          },
          slideChange: swiper => {
            this.activeIndex = swiper.activeIndex;
          }
        }
      };
      this.swiperOptions = swiperOptions;
      const swiper = new Swiper(this.$refs.swiper, swiperOptions);
      // eslint-disable-next-line no-unused-vars
      const updateAutoHeight = swiper.updateAutoHeight;
      this.$swiper = swiper;
      this.$emit('ready', swiper);
    }
  }
};
