
import MxLink from './MxLink.vue';

export default {
  components: { MxLink },

  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
};
