
import NewsInfoSmall from './NewsInfoSmall.vue';
import NewsInfoBig from './NewsInfoBig.vue';
import NewsInfoTitle from './NewsInfoTitle.vue';

export default {
  components: { NewsInfoSmall, NewsInfoBig, NewsInfoTitle },
  props: {
    obj: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      default: 0
    }
  },

  computed: {
    type() {
      return this.index % 3;
    },

    listData() {
      let listData;
      const list = this.obj.content_list;
      switch (this.type) {
        case 0:
          listData = list.slice(0, 6);
          break;
        case 1:
          listData = list.slice(0, 8);
          break;
        case 2:
          listData = list.slice(0, 4);
          break;
      }
      return listData;
    }
  },

  methods: {
    jumpPage({ channel_id, channel_type }) {
      if (channel_type == 1) {
        this.$router.push({
          path: this.localePath(`/m/topic/${channel_id}`, this.$i18n.locale)
        });
        return;
      }

      this.$router.push({
        path: this.localePath(`/m/channel/${channel_id}`, this.$i18n.locale)
      });
    }
  }
};
