import { render, staticRenderFns } from "./Channel.vue?vue&type=template&id=2a76a678&scoped=true&"
import script from "./Channel.vue?vue&type=script&lang=js&"
export * from "./Channel.vue?vue&type=script&lang=js&"
import style0 from "./Channel.vue?vue&type=style&index=0&id=2a76a678&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a76a678",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('D:/ruanjian/MX/tnaot_www_pc/components/Icon.vue').default,NewsInfoBig: require('D:/ruanjian/MX/tnaot_www_pc/components/NewsInfoBig.vue').default,NewsInfoSmall: require('D:/ruanjian/MX/tnaot_www_pc/components/NewsInfoSmall.vue').default,NewsInfoTitle: require('D:/ruanjian/MX/tnaot_www_pc/components/NewsInfoTitle.vue').default})
