
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    bannerArray() {
      const list = this.list || [];
      return list.length === 2 ? [...list, ...list] : list;
    }
  },

  methods: {
    bannerJump(obj) {
      // type = 3 不做跳转
      if (obj.type !== 3) {
        // 跳转第三方链接
        if (obj && obj.pageType === 4) {
          window.open(obj.url);
        } else {
          this.$router.push({
            path: this.localePath('/m/download', this.$i18n.locale)
          });
        }
      }
    }
  }
};
