
export default {
  name: 'SwiperItem',

  props: {
    slideStyle: {
      type: [String, Array, Object],
      default: null
    }
  },

  beforeDestroy() {
    this.deregisterUpdater();
  },

  methods: {
    getImages() {
      const { container } = this.$refs;
      if (!container) return [];
      const children = container.children;
      return Array.prototype.filter.call(
        children,
        child => child.tagName.toLowerCase() === 'img'
      );
    },

    updateWhenImageReady() {
      const images = this.getImages();
      images.forEach(image => {
        image.addEventListener('load', this.onImageLoad);
      });
    },

    onImageLoad() {
      const swiper = this.$parent.$swiper;
      if (swiper) {
        swiper.update();
        swiper.updateAutoHeight();
      }
    },

    deregisterUpdater() {
      const images = this.getImages();
      images.forEach(image => {
        image.removeEventListener('load', this.onImageLoad);
      });
    }
  },

  render() {
    this.deregisterUpdater();
    this.$nextTick(() => {
      this.updateWhenImageReady();
    });
    return (
      <div class='swiper-slide' style='slideStyle' ref='container'>
        {this.$slots.default}
      </div>
    );
  }
};
